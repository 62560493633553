* {
  box-sizing: border-box;
  margin: unset;
  padding: unset;
}

html,
body {
  height: 100%;
}

body {
  align-items: center;
  display: flex;
}

canvas {
  margin-inline: auto;
}
